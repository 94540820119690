<template>
  <div class="box">
    <div class="title">
      <div class="tt">
        管理团队
        <div class="bg"></div>
      </div>
    </div>
    <van-row>
      <van-col
        :span="24"
        v-for="(item, index) in people"
        :key="index"
        class="td"
      >
        <div class="leftbox">
          <div class="name">
            {{ item.name }}
          </div>
          <div class="zhiwei">
            {{ item.position }}
          </div>
          <div class="jieshao">
            {{ item.jieshao }}
          </div>
        </div>
        <div class="rightbox">
          <!-- <div class="bgimg"></div> -->
          <img :src="item.img" alt="" />
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "HomeMid",
  data() {
    return {
      currentIndex: 0,
      nowprogress: 0,
      time: 0,
      people: [
        {
          img: require("../../../../assets/mobile/a1.png"),
          name: "宋阳",
          position: "首席执行官",
          jieshao:
            "宋阳先生是瑞尔安心的创始人和首席执行官，为公司的所有业务职能及资源合作提供战略指导。",
        },
        {
          img: require("../../../../assets/mobile/a2.png"),
          name: "林子怀",
          position: "首席技术官",
          jieshao:
            "林子怀先生是瑞尔安心的联合创始人、海外研究院院长兼首席技术官，负责指导公司的硬件产品研发及技术革新。",
        },
        {
          img: require("../../../../assets/mobile/a3.png"),
          name: "Branka Vucetic",
          position: "董事",
          jieshao: "Branka Vucetic院士是瑞尔安心的董事。",
        },
      ],
    };
  },
};
</script>

<style scoped>
.box {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.title .tt {
  position: relative;
  color: #252222;
  font-size: 1.25rem;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 5rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.td {
  display: flex;
  margin-top: 3.75rem;
}
.leftbox {
  flex: 1;
  margin-right: 0.75rem;
}
.leftbox .name {
  margin-top: 1.5rem;
  color: #383ebd;
  font-size: 1rem;
}
.leftbox .zhiwei {
  margin-top: 0.25rem;
  color: #383ebd;
  color: #524f4f;
  font-size: 0.75rem;
}
.leftbox .jieshao {
  color: #524f4f;
  font-family: "OPPOSansR";
  font-size: 0.75rem;
  margin-top: 0.75rem;
}
.rightbox {
  position: relative;
  width: 9.3rem;
  height: 10rem;
}
.rightbox img {
  width: 100%;
  height: 100%;
}
.bgimg {
  position: absolute;
  z-index: -1;
  margin-top: 0.8525rem;
  /* margin-left: -0.8125rem; */
  width: 3.3125rem;
  height: 9.75rem;
  background: url("~assets/about/pbg.png");
  background-size: 5.3125rem 9.75rem;
  background-repeat: no-repeat;
}
</style>
