<template>
  <div class="box">
    <div class="topbox">
      <div class="leftbox">
        <div class="content">
          <span class="title"
            >我是患者或家属
            <div class="bgcolor"></div
          ></span>
          <div class="neirong">
            了解Anxin动态心电记录仪如何帮助心血管疾病患者及高风险人群更好的应对疾病
          </div>
          <div class="rightbox">
            <van-row type="flex" justify="center">
              <van-col class="col_img">
                <div class="bg">
                  <div class="imgsize">
                    <img src="~assets/mobile/h1.png" alt="" />
                  </div>
                </div>
              </van-col>
              <van-col>
                <span class="btn" @click="togo(1)">了解更多</span></van-col
              >
            </van-row>
          </div>
        </div>
      </div>
    </div>
    <div class="bottombox">
      <div class="leftbox">
        <div class="content">
          <span class="title"
            >我是专业人员
            <div class="zbgcolor"></div
          ></span>
          <div class="neirong">
            了解安心服务的模式以及其为医疗与健康行业带来的革命性变化
          </div>
          <div class="rightbox">
            <van-row type="flex" justify="center">
              <van-col class="col_img">
                <div class="bg">
                  <div class="imgsize">
                    <img src="~assets/mobile/z1.png" alt="" />
                  </div>
                </div>
              </van-col>
              <van-col>
                <span class="btn" @click="togo(2)">了解更多</span></van-col
              >
            </van-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    togo(index) {
      if (index === 1) {
        this.$router.push({ path: "/topatient" });
      }
      if (index === 2) {
        this.$router.push({ path: "/toperson" });
      }
    },
  },
};
</script>

<style scoped>
.topbox {
  border-bottom: 1px solid #e4e4e4;
}
.rightbox {
  /* width: 17.0625rem;
  height: 11rem; */
  text-align: center;
  margin-bottom: 6.25rem;
}
.rightbox .imgsize img {
  /* margin-top: 3.125rem; */
  /* width: 100%; */
  height: 100%;
  /* margin-bottom: 1.75rem; */
}
.leftbox {
  flex: 1;
}
.leftbox .title {
  position: relative;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #252222;
}
.leftbox .bgcolor {
  position: absolute;
  background-color: #0093b3;
  right: 0;
  bottom: 0;
  z-index: -10;
  opacity: 0.4;
  width: 6.125rem;
  height: 0.375rem;
}
.leftbox .zbgcolor {
  position: absolute;
  background-color: #0093b3;
  right: 0;
  bottom: 0;
  z-index: -10;
  opacity: 0.4;
  width: 4.925rem;
  height: 0.375rem;
}
.content {
  margin: 0rem 1.5rem;
}
.neirong {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #7e7e7e;
  margin-top: 1.25rem;
  font-family: "OPPOSansR";
}
.van-button {
  margin-top: 9.948vw;
  /* width: 10.417vw;
  height: 3.125vw; */
  border-radius: 0.521vw;
  margin-bottom: 7.813vw;
}
.van-button--normal {
  font-size: 1.042vw;
}
.bottombox .rightbox {
  margin-top: 4.167vw;
}
.bottombox .leftbox {
  margin-top: 5.99vw;
}
.content .btn {
  color: #ffffff;
  background-color: #383ebd;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border-radius: 4px;
  padding: 0.5rem 3.75rem;
  text-align: center;
}
.rightbox .imgsize {
  width: 17.0625rem;
  height: 11.625rem;
  padding-top: 2rem;
}
.rightbox .bg {
  width: 20.625rem;
  height: 15.625rem;
  background: url("~assets/mobile/bg.png") no-repeat;
  background-size: 100%;
}
.col_img {
  margin: 1.75rem 0;
}
</style>
