<template>
  <div>
    <div class="banner index-hero" :style="{ height: screenWidth + 'px' }">
      <!-- 若要铺满整个屏幕 -->
      <!-- <div class="banner index-hero" ref="banner" > --><!-- 若要设定高度 -->
      <!-- <div class="index-hero__logo">
        <img src="~assets/shebei2.png" alt="" />
      </div> -->
      <div class="title">
        <div class="anname">安心系统</div>
        <div class="r">&reg;</div>
      </div>
      <van-row class="jieshao">
        <van-col span="24">
          <span>
            一套完善的<span class="bbtitle">心脏健康监测管理方案</span
            >，可以为患者提供全周期的心血管健康管理。
          </span>
        </van-col>
      </van-row>
      <!-- <van-row class="cont">
        <van-col span="24" v-for="(item, index) in asd" :key="index">
          <span class="bt">{{ item.title }}:</span
          ><span>{{ item.content }}</span>
        </van-col>
      </van-row> -->
      <div class="arrow"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      // screenWidth: document.documentElement.clientHeight,
      screenWidth: 509,
      // asd: [
      //   {
      //     title: "专业",
      //     content: "专为患者设计的医疗级可穿戴心电检测记录设备",
      //   },
      //   {
      //     title: "持久",
      //     content: "长达7天的不间断心电记录",
      //   },
      //   {
      //     title: "舒适",
      //     content: "低致敏性高分子仿生凝胶电极带来的无感佩戴体验",
      //   },
      //   {
      //     title: "准确",
      //     content: "专有降噪算法辅助高灵敏度心电传感器，记录高质量ECG数据",
      //   },
      //   {
      //     title: "可信",
      //     content: "人工智能辅助资深心电诊断医师，提供高精度的心电诊断报告",
      //   },
      // ],
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = this.screenWidth;
        that.screenWidth = window.fullHeight;
      })();
    };
    // console.log(this.$refs.banner)
    // this.$bus.emit("bannerheight", this.$refs.banner.clientHeight);
  },
  watch: {
    screenWidth(val) {
      console.log(val);
      this.screenWidth = val;
    },
  },
  methods: {
    imageLoad() {
      if (!this.isLoad) {
        // this.$emit('sImageLoad')
        this.isLoad = true;
      }
    },
  },
};
</script>
<style scoped>
/* @import "~assets/css/mobile/vantbannerimg.css"; */
.banner {
  background-image: url("~assets/mobile/mbg.png");
  /* background-image: url("~assets/banner.png"); */
  /* height: 31.1875rem; */
}
.index-hero {
  display: block;
  position: relative;
  /* width: 100vw; */
  /* min-height: 100%; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition-duration: 0.6s;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo {
  position: absolute;
  width: 100%;
  height: 49.6%;
  top: -20%;
  left: 0;
  bottom: 0;
  right: 0;
  /* margin: auto; */
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  /* background: url("~assets/q1.png") no-repeat; */
  background-size: 100%;
  /* margin-left: auto; */
  /* width: 72%;
  height: 92%; */
  transition-duration: 1.6s;
  transition-delay: 1s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo img {
  width: 100%;
  /* height: 100%; */
  opacity: 0.8;
}
.title {
  position: absolute;
  display: flex;
  left: 6%;
  top: 15%;
  color: #ffffff;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.title .anname {
  font-size: 2.25rem;
}
.r {
  margin-left: 0.625vw;
  margin-top: 0.533vw;
  font-size: 1.5rem;
}
.jieshao {
  position: absolute;
  left: 6%;
  top: 28%;
  right: 6%;
  color: #e4e4e4;
  font-family: "OPPOSansR";
  font-size: 1rem;
  line-height: 1.5rem;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.jieshao .bbtitle {
  color: #8489ff;
}
/* .cont {
  position: absolute;
  left: 8%;
  right: 8%;
  top: 50%;
  font-family: "OPPOSansR";
  font-size: 0.75rem;
  line-height: 1rem;
  color: #e4e4e4;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  margin-top: 0.2%;
}
.cont span {
  color: #a4a0a0;
}
.cont .van-col {
  margin-top: 1em;
}
.cont .bt {
  color: #8489ff;
  margin-right: 0.5em;
} */
.arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-left: 1px solid #f7f7f5;
  border-bottom: 1px solid #f7f7f5;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: arrow;
  animation-name: arrow;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-duration: 1.6s;
  transition-delay: 2.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
@keyframes arrow {
  0% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    -webkit-transform: translate3d(0, 6px, 0) rotate(-45deg);
    transform: translate3d(0, 6px, 0) rotate(-45deg);
  }
  100% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
</style>
