<template>
  <div class="home">
    <div class="zhegaibox" v-if="zhegai"></div>
    <home-navigation class="nav" @showzhegai="showzhegai"></home-navigation>
    <home-banner></home-banner>
    <home-content class="content"></home-content>
    <home-say class="say"></home-say>
    <home-people class="people"></home-people>
  </div>
</template>

<script>
import HomeNavigation from "./homeChild/HomeNavigation";
import HomeBanner from "./homeChild/HomeBanner";
import HomeContent from "./homeChild/HomeContent";
import HomeSay from "./homeChild/HomeSay";
import HomePeople from "./homeChild/HomePeople";

export default {
  components: {
    HomeBanner,
    HomeContent,
    HomeSay,
    HomePeople,
    HomeNavigation,
  },
  data() {
    return {
      zhegai: false,
    };
  },
  methods: {
    showzhegai(val) {
      console.log(val);
      if (val === "showzhegai") {
        this.zhegai = true;
      }
      if (val === "wushowzhegai") {
        this.zhegai = false;
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 3.75rem;
}
.say {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}
.people {
  /* margin-top: 5.052vw; */
}
.nav {
  z-index: 99;
}
.zhegaibox {
  position: absolute;
  top: 0;
  height: 10000%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252222;
  opacity: 0.5;
  z-index: 10;
}
</style>
