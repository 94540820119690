<template>
  <div>
    <div
      class="banner index-hero"
      ref="banner"
      :style="{ height: screenWidth + 'px' }"
    >
      <div class="index-hero__logo">
        <!-- <img src="~assets/shebei.png" alt="" @load="imageLoad" /> -->
      </div>
      <div class="title">
        <div class="anname">关于瑞尔安心</div>
        <!-- <div class="r">&reg;</div> -->
      </div>
      <van-row class="jieshao">
        <van-col span="24">
          <span>
            瑞尔安心是一家专注于慢性病监测与管理的数字化医疗解决方案提供商。自2015年创立以来，我们一直致力于将可穿戴技术、人工智能与医学临床实践深度融合，并赋能医疗机构及医生个体，为临床实践提供及时、准确、全面的高质量临床证据。通过技术革新不断提高临床诊疗效率、降低护理成本、促进医学模式转变。
          </span>
        </van-col>
      </van-row>
      <div class="arrow"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // screenWidth: document.documentElement.clientHeight,
      screenWidth: 509,
    };
  },
  watch: {
    screenWidth(val) {
      console.log(val);
      this.screenWidth = val;
    },
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = this.screenWidth;
        that.screenWidth = window.fullHeight;
      })();
    };
    // this.$bus.emit("bannerheight", this.$refs.banner.clientHeight);
  },
};
</script>

<style scoped>
.banner {
  background-image: url("~assets/mobile/gybg.png");
  /* height: 31.1875rem; */
}
.index-hero {
  display: block;
  position: relative;
  /* width: 100vw; */
  /* min-height: 100%; */
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition-duration: 0.6s;
  transition-delay: 0ms;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* margin: auto; */
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  background: url("~assets/q1.png") no-repeat;
  background-size: 100%;
  /* margin-left: auto; */
  width: 72%;
  height: 92%;
  transition-duration: 1.6s;
  transition-delay: 1s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.index-hero__logo img {
  width: 100%;
  height: 100%;
}
.title {
  position: absolute;
  display: flex;
  left: 8%;
  top: 18%;
  color: #ffffff;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.title .anname {
  font-size: 1.25rem;
}
.r {
  margin-left: 0.625vw;
  margin-top: 0.533vw;
  font-size: 1.233vw;
}
.jieshao {
  position: absolute;
  left: 6%;
  top: 32%;
  right: 6%;
  color: #e4e4e4;
  font-family: "OPPOSansR";
  font-size: 0.75rem;
  line-height: 1rem;
  transition-duration: 1.6s;
  transition-delay: 1.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
.jieshao span {
  line-height: 1.15rem;
}
.arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  border-left: 1px solid #f7f7f5;
  border-bottom: 1px solid #f7f7f5;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation-name: arrow;
  animation-name: arrow;
  -webkit-animation-duration: 1.6s;
  animation-duration: 1.6s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  transition-duration: 1.6s;
  transition-delay: 2.6s;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}
@keyframes arrow {
  0% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
  50% {
    -webkit-transform: translate3d(0, 6px, 0) rotate(-45deg);
    transform: translate3d(0, 6px, 0) rotate(-45deg);
  }
  100% {
    -webkit-transform: translate3d(0, -6px, 0) rotate(-45deg);
    transform: translate3d(0, -6px, 0) rotate(-45deg);
  }
}
</style>
