var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbox"},[_vm._m(0),_vm._l((_vm.jieshao),function(item,index){return _c('van-row',{key:index,staticClass:"box"},[_c('van-col',{staticClass:"itemtitle",attrs:{"span":"24"}},[_vm._v(_vm._s(item.title))]),_c('van-col',{staticClass:"msg",attrs:{"span":"24"}},[_vm._v(_vm._s(item.msg))]),(index === 1)?_c('div',{staticClass:"box1"},[_c('div',{staticClass:"img1 fade-in"},[_c('div',{staticClass:"imgbg"},[_c('img',{attrs:{"src":require("assets/patient/bg.png"),"alt":""}})]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.img),expression:"item.img"}],attrs:{"alt":""}})]),_c('div',{staticClass:"img2 fade-in"},_vm._l((item.img1),function(item,index){return _c('div',{key:index,staticClass:"imgsize"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)]):_vm._e(),(index === 0)?_c('div',{staticClass:"box2"},[_c('div',{staticClass:"img2 fade-in"},_vm._l((item.img),function(item,index){return _c('div',{key:index,staticClass:"imgsize",style:({
            width: index != 0 ? 3.8125 + 'rem' : 8.1875 + 'rem',
            height: 4.8125 + 'rem',
            float: index != 0 ? 'left' : 'none',
            'margin-left': index == 2 ? 0.5625 + 'rem' : 0,
          })},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0),_c('div',{staticClass:"img1 fade-in"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.img1),expression:"item.img1"}],attrs:{"alt":""}})])]):_vm._e(),(index === 2)?_c('div',{staticClass:"box2"},[_c('div',{staticClass:"img1 fade-in"},[_c('div',{staticClass:"imgbg"},[_c('img',{attrs:{"src":require("assets/patient/bg.png"),"alt":""}})]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.img),expression:"item.img"}],attrs:{"alt":""}})]),_c('div',{staticClass:"img2 fade-in"},_vm._l((item.img1),function(item,index){return _c('div',{key:index,staticClass:"imgsize"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item),expression:"item"}],attrs:{"alt":""}})])}),0)]):_vm._e()],1)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"tt"},[_vm._v(" Anxin动态心电记录仪适合哪些患者 "),_c('div',{staticClass:"bg"})]),_c('div',{staticClass:"jieshao"},[_vm._v(" Anxin动态心电记录仪已经过NMPA国家认证，并在临床实践中得到了广大医生的认可。 ")])])
}]

export { render, staticRenderFns }