<template>
  <div class="topbox">
    <div class="title">
      <div class="tt">
        Anxin动态心电记录仪适合哪些患者
        <div class="bg"></div>
      </div>
      <div class="jieshao">
        Anxin动态心电记录仪已经过NMPA国家认证，并在临床实践中得到了广大医生的认可。
      </div>
    </div>
    <van-row class="box" v-for="(item, index) in jieshao" :key="index">
      <van-col span="24" class="itemtitle">{{ item.title }}</van-col>
      <van-col span="24" class="msg">{{ item.msg }}</van-col>
      <div v-if="index === 1" class="box1">
        <div class="img1 fade-in">
          <div class="imgbg">
            <img src="~assets/patient/bg.png" alt="" />
          </div>
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="img2 fade-in">
          <div class="imgsize" v-for="(item, index) in item.img1" :key="index">
            <img v-lazy="item" alt="" />
          </div>
        </div>
      </div>
      <div v-if="index === 0" class="box2">
        <div class="img2 fade-in">
          <div
            class="imgsize"
            v-for="(item, index) in item.img"
            :key="index"
            :style="{
              width: index != 0 ? 3.8125 + 'rem' : 8.1875 + 'rem',
              height: 4.8125 + 'rem',
              float: index != 0 ? 'left' : 'none',
              'margin-left': index == 2 ? 0.5625 + 'rem' : 0,
            }"
          >
            <img v-lazy="item" alt="" />
          </div>
        </div>
        <div class="img1 fade-in">
          <!-- <div class="imgbg1">
            <img src="~assets/patient/bg2.png" alt="" />
          </div> -->
          <img v-lazy="item.img1" alt="" />
        </div>
      </div>
      <div v-if="index === 2" class="box2">
        <div class="img1 fade-in">
          <div class="imgbg">
            <img src="~assets/patient/bg.png" alt="" />
          </div>
          <img v-lazy="item.img" alt="" />
        </div>
        <div class="img2 fade-in">
          <div class="imgsize" v-for="(item, index) in item.img1" :key="index">
            <img v-lazy="item" alt="" />
          </div>
        </div>
      </div>
    </van-row>
  </div>
</template>

<script>
import { isElemVisible } from "components/common/common.js";
export default {
  name: "PatientTop",
  data() {
    return {
      jieshao: [
        {
          title: "提前发现疾病征兆，赢得治疗先机",
          msg: "中老年人、肥胖、更年期、高血压、缺乏运动、长期加班、高度紧张、不明原因胸痛、心悸患者、心血管疾病高危人群",
          img: [
            require("../../../../assets/patient/2-1.png"),
            require("../../../../assets/patient/2-2.png"),
            require("../../../../assets/patient/2-3.png"),
          ],
          img1: require("../../../../assets/patient/2-4.png"),
        },
        {
          title: "即时监测治疗效果，精准调整用药方案。",
          msg: "妊娠期心脏病、心脏病患者、心血管术后患者、植入起搏器的患者、肿瘤化疗期治疗患者",
          img: require("../../../../assets/patient/2-9.png"),
          img1: [
            require("../../../../assets/patient/2-11.png"),
            require("../../../../assets/patient/2-10.png"),
          ],
        },
        {
          title: "睡眠监测、运动评估、让健康生活看得见！",
          msg: "睡眠监测、运动负荷、临床科研",
          img: require("../../../../assets/patient/2-6.png"),
          img1: [
            require("../../../../assets/patient/2-7.png"),
            require("../../../../assets/patient/2-8.png"),
          ],
        },
      ],
    };
  },
  mounted() {
    // 先获取全部需要过渡效果的元素
    this.fadeInElements = Array.from(
      document.getElementsByClassName("fade-in")
    );
    // 监听鼠标滚动事件
    document.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 循环判断是否要触发过渡
    handleScroll() {
      for (var i = 0; i < this.fadeInElements.length; i++) {
        var elem = this.fadeInElements[i];
        if (isElemVisible(elem, 200)) {
          elem.style.opacity = "1";
          elem.style.transform = "scale(1)";
          this.fadeInElements.splice(i, 1); // 只让它运行一次
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.topbox {
  margin-left: 1.5rem;
  /* margin-right: 1.5rem; */
}
.title .tt {
  color: #252222;
  font-size: 1.25rem;
  /* line-height: 1.875rem; */
  position: relative;
}
.fade-in {
  height: 26.042vw;
  margin-bottom: 2.604vw;
  opacity: 0;
  transition: 0.3s all ease-out;
  transform: scale(0.8);
  box-sizing: border-box;
  padding-left: 1.042vw;
  display: inline-block;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 12.3rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.title .jieshao {
  margin-top: 1.25rem;
  color: #7e7e7e;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: "OPPOSansR";
  margin-right: 1.5rem;
}
.box {
  margin-top: 5.208vw;
  margin-right: 1.5rem;
  /* width: 61.979vw;
  height: 34.479vw; */
}
.itemtitle {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 2.5rem;
  color: #252222;
}
.msg {
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: "OPPOSansR";
  color: #7e7e7e;
  margin-top: 1.875rem;
  margin-bottom: 1.5rem;
}
.box1,
.box2,
.box3 {
  display: flex;
  width: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.box1 .img1,
.box2 .img1 {
  width: 12.4375rem;
  height: 10.375rem;
}
.box1 .img2,
.box2 .img2 {
  margin-left: 1.823vw;
}
.box1 .img2 .imgsize,
.box2 .img2 .imgsize {
  width: 8.1875rem;
  height: 4.8125rem;
  margin-bottom: 0.75rem;
}
.box3 .img1 {
  width: 20.469vw;
  height: 26.042vw;
}
.box3 .img2 .imgsize {
  width: 11.073vw;
  height: 10.938vw;
  margin-top: 2.083vw;
  margin-left: 1.823vw;
}
.box3 .img2 {
  width: 32.813vw;
  height: 26.042vw;
}
.imgbg {
  /* width: 65%; */
  position: absolute;
  width: 12.396vw;
  top: 19vw;
  left: -0.88vw;
  height: 7.656vw;
  z-index: -9;
}
.imgbg1 {
  /* width: 65%; */
  position: absolute;
  width: 12.396vw;
  top: -1vw;
  right: -0.88vw;
  height: 7.656vw;
  z-index: -9;
}
.imgbg img {
  width: 100%;
  height: 100%;
}
</style>
