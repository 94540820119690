<template>
  <div class="tbox">
    <van-row class="neirong">
      <van-col :span="24">
        <div class="biaoti">可信的动态心电诊断报告</div>
      </van-col>
      <van-col :span="24">
        <div class="js">
          每一份心电诊断报告都由资深心电诊断医师在基于深度学习的人工智能辅助下完成，并经过瑞尔安心健康中心的专家组审核，拥有极高的可信度。
        </div>
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col>
        <div class="imgsize">
          <img src="~assets/record.png" alt="" />
        </div>
      </van-col>
    </van-row>
    <!-- <van-col :span="12">
      <div class="imgsize">
        <img src="~assets/toperson/tu1.png" alt="" />
      </div>
    </van-col> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.neirong .biaoti {
  color: #252222;
  font-size: 0.75rem;
}
.neirong .js {
  margin-top: 1.875rem;
  font-size: 0.75rem;
  color: #7e7e7e;
  font-family: "OPPOSansR";
}
.imgsize {
  width: 12.5rem;
  height: 10.375rem;
}
.imgsize img {
  width: 100%;
  height: 100%;
}
.img_js {
  align-items: center;
}
.neirong {
  margin-bottom: 1.5rem;
}
</style>
