<template>
  <div class="tbox">
    <van-row>
      <van-col :span="24" class="title">
        <div class="tt">
          临床研究
          <div class="bg"></div>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col :span="24" class="jieshao">
        瑞尔安心作为领先的数字医疗设备提供商，一直致力于帮助企业及研究者获取更准确、可信的第一手临床实验数据，助力全球心血管系统新药物与新疗法的诞生
      </van-col>
    </van-row>
    <van-row type="flex" justify="center space-around">
      <van-col
        :span="12"
        v-for="(item, index) in research"
        :key="index"
        class="asd"
      >
        <div
          class="imgstyle"
          :style="{
            width: `${item.imgwidth}rem`,
            height: `${item.imgheight}rem`,
            top: `${item.top}%`,
          }"
        >
          <img :src="item.img" alt="" />
        </div>
        <div
          class="fontstyle"
          :style="{
            top: `${item.top}%`,
          }"
        >
          {{ item.title }}
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      research: [
        {
          img: require("../../../../assets/patient/asd.png"),
          title: "高危人群心血管事件预警",
          imgwidth: 9.125,
          imgheight: 9.125,
          // left: 50,
          // translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r1.png"),
          title: "癫痫患者发病监测",
          imgwidth: 6.5,
          imgheight: 6.5,
          // left: 50,
          // translate: "translateX(-50%)",
          // bottom: -20,
        },
        {
          img: require("../../../../assets/research/r2.png"),
          title: "药物副作用/不良事件监测",
          imgwidth: 6.5,
          imgheight: 6.5,
          // left: 50,
          // translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r3.png"),
          title: "房颤患者筛查",
          imgwidth: 9.125,
          imgheight: 9.125,
          top: -20,
          // left: 50,
          // translate: "translateX(-50%)",
          // bottom: -20,
        },
        {
          img: require("../../../../assets/research/r4.png"),
          title: "心血管疾病诊断",
          imgwidth: 9.125,
          imgheight: 9.125,
          top: -20,
          // left: 50,
          // translate: "translateX(-50%)",
          // bottom: -15,
        },
        {
          img: require("../../../../assets/research/r7.png"),
          title: "睡眠呼吸暂停风险评估",
          imgwidth: 6.5,
          imgheight: 6.5,
          top: -20,
          // left: 50,
          // translate: "translateX(-50%)",
        },
        {
          img: require("../../../../assets/research/r5.png"),
          title: "心肌负荷评估",
          imgwidth: 6.5,
          imgheight: 6.5,
          top: -20,
          // left: 50,
          // translate: "translateX(-50%)",
          // bottom: -10,
        },
        {
          img: require("../../../../assets/research/r6.png"),
          title: "心血管药物效果评价",
          imgwidth: 9.125,
          imgheight: 9.125,
          top: -40,
          // left: 50,
          // translate: "translateX(-50%)",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title .tt {
  position: relative;
  color: #252222;
  font-size: 1.25rem;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 5rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  margin-top: 1.25rem;
  font-size: 0.75rem;
  color: #7e7e7e;
  font-family: "OPPOSansR";
}
.fontstyle {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #7e7e7e;
  text-align: center;
  position: relative;
}
.imgstyle img {
  width: 100%;
  height: 100%;
}
.imgstyle {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.asd {
  position: relative;
  margin-top: 1.25rem;
}
</style>
