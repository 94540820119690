<template>
  <div>
    <how-logo :coo="coo"></how-logo>
  </div>
</template>

<script>
import HowLogo from "components/content/logo/mobile/HowLogo";
export default {
  name: "PatientInsurance",
  components: {
    HowLogo,
  },
  data() {
    return {
      coo: {
        title: "战略合作",
        cooperation: [
          {
            img: require("../../../../assets/mobile/logo/coo2.png"),
          },
          {
            img: require("../../../../assets/mobile/logo/coo9.png"),
          },
          {
            img: require("../../../../assets/mobile/logo/coo6.png"),
          },
          {
            img: require("../../../../assets/mobile/logo/coo1.png"),
          },

          {
            img: require("../../../../assets/mobile/logo/coo3.png"),
          },
        ],
      },
    };
  },
};
</script>

<style></style>
