<template>
  <div class="box">
    <van-row class="cont">
      <van-col span="24"> 慢病药品保障计划</van-col>
    </van-row>
    <van-row class="touse">
      <van-col span="24">
        <span>好药不贵，吃得起</span>
      </van-col>
    </van-row>
    <div class="midbox">
      <van-row>
        <van-col class="title"> 慢病药物3折购，会员购药全场三折 </van-col>
        <van-col class="title conte">
          会员商城精心筛选大厂品牌药物，覆盖15个科室、258种常见疾病的药物需求，质量有保障，价格更低廉，全场药物免费包邮。
        </van-col>
        <van-col>
          <div class="img">
            <img src="../../../../assets/p1.png" alt="" />
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.cont {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
  color: #252222;
}
.cont span {
  color: red;
  opacity: 0.8;
}
.touse {
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.75rem;
  font-family: "OPPOSansR";
  color: #524f4f;
}
.midbox {
  box-sizing: border-box;
  margin: 0 1.5rem;
  margin-top: 3.75rem;
}
.title {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #252222;
  margin-bottom: 0.75rem;
}
.conte {
  color: #7e7e7e;
}
.img {
  width: 20.4375rem;
  height: 10.375rem;
}
img {
  width: 100%;
  height: 100%;
}
</style>
