<template>
  <div class="box">
    <div class="zhegaibox" v-if="zhegai"></div>
    <home-navigation class="nav" @showzhegai="showzhegai"></home-navigation>
    <about-top class="topbox"></about-top>
    <about-mid class="midbox"></about-mid>
    <about-consultant class="consultant"></about-consultant>
    <about-cooperation class="cooperation"></about-cooperation>
  </div>
</template>

<script>
import HomeNavigation from "../home/homeChild/HomeNavigation";
import AboutTop from "./homeChild/AboutTop";
import AboutMid from "./homeChild/AboutMid";
import AboutConsultant from "./homeChild/AboutConsultant";
import AboutCooperation from "./homeChild/AboutCooperation";
export default {
  name: "AboutUs",
  components: {
    AboutTop,
    AboutMid,
    AboutConsultant,
    AboutCooperation,
    HomeNavigation,
  },
  data() {
    return {
      zhegai: false,
    };
  },
  methods: {
    showzhegai(val) {
      console.log(val);
      if (val === "showzhegai") {
        this.zhegai = true;
      }
      if (val === "wushowzhegai") {
        this.zhegai = false;
      }
    },
  },
};
</script>

<style scoped>
.topbox {
}
.midbox {
  margin-top: 3.75rem;
}
.consultant {
  margin-top: 6.25rem;
  margin-bottom: 3.75rem;
}
.cooperation {
  margin-bottom: 6.25rem;
}
.zhegaibox {
  position: absolute;
  top: 0;
  height: 10000%;
  bottom: 0;
  left: 0;
  right: 0;
  background: #252222;
  opacity: 0.5;
  z-index: 10;
}
</style>
