<template>
  <div>
    <van-row>
      <van-col span="24" class="title">{{ coo.title }}</van-col>
    </van-row>
    <van-row>
      <van-col span="24" class="msg">{{ coo.msg }}</van-col>
    </van-row>
    <div class="lo">
      <van-row type="flex">
        <van-col
          :span="4"
          v-for="(item, index) in coo.cooperation"
          :key="index"
          offset="1"
        >
          <div class="img">
            <img :src="item.img" alt="" />
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutCooperation",
  props: {
    coo: {
      typeof: Array,
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 1.25rem;
  line-height: 1.875rem;
  margin: 0 auto;
  text-align: center;
}
.lo {
  margin-top: 2.5rem;
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}
.msg {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.75rem;
  color: #524f4f;
  text-align: center;
  font-family: "OPPOSansR";
}
.img {
  width: 5rem;
  height: 4.0625rem;
  /* margin-left: 3.125vw; */
}
.van-col--4 {
  width: 28%;
}
.img img {
  width: 100%;
  height: 100%;
}
.lo .van-row {
  justify-content: center;
}
</style>
