<template>
  <div class="pmanage tbox">
    <van-row>
      <van-col :span="24" class="ptitle"> 多平台管理 </van-col>
      <van-col :span="24" class="pjs"> 使用多个平台对患者进行管理 </van-col>
    </van-row>
    <section class="zong">
      <van-card class="as-card" v-for="(item, index) in distype" :key="index">
        <template #tags>
          <div class="imgsize">
            <img :src="item.img1" />
          </div>
          <div class="ztitle">
            <div v-if="index === 0">
              {{ item.title1 }}
            </div>
            <div v-else>
              <a @click="tonew">{{ item.title1 }}>></a>
            </div>
          </div>
          <div class="ztitle2">{{ item.title2 }}</div>
        </template>
      </van-card>
    </section>
  </div>
</template>

<script>
export default {
  name: "PersonManage",
  data() {
    return {
      distype: [
        {
          img1: require("../../../../assets/mobile/P1.png"),
          title1: "适用于机构",
          title2:
            "利用安心患者管理门户 对患者进行管理和不间断随访干预，支持本地分析患者心电数据或上传后由瑞尔安心健康中心的资深心电诊断医师分析并出具报告。",
        },
        {
          img1: require("../../../../assets/mobile/s3.png"),
          title1: "适用于个人医师",
          title2:
            "下载并注册瑞尔良医APP，在线管理患者  调整医嘱，远程随访、实时干预。",
        },
      ],
    };
  },
  methods: {
    tonew: function () {
      window.open("http://api.irealcare.com/weixin/product/down/doctorApp");
    },
  },
};
</script>

<style scoped>
@import "~assets/css/mobile/vantimg.css";
.pmanage .ptitle {
  color: #252222;
  font-size: 1.25rem;
  text-align: center;
}
.pmanage .pjs {
  color: #524f4f;
  font-size: 0.75rem;
  text-align: center;
  margin-top: 0.75rem;
  font-family: "OPPOSansR";
}
.imgsize {
  /* width: 20.4375rem; */
  /* height: 11.375rem; */
}
.imgsize img {
  width: 100%;
  border-radius: 0.5rem;
  /* margin-left: -7%; */
  /* height: 100%; */
}
.ztitle {
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #383ebd;
}
.ztitle a {
  border-bottom: 1px solid #383ebd;
}
.ztitle2 {
  font-size: 0.75rem;
  font-family: "OPPOSansR";
  color: #7e7e7e;
  margin-top: 1.125rem;
  margin-bottom: 1rem;
}
.as-card {
  margin-top: 2.5rem;
  border-radius: 0.5rem;
}
</style>
