<template>
  <div class="tbox">
    <van-row>
      <van-col :span="24" class="title">
        <div class="tt">
          安心服务
          <div class="bg"></div>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col :span="24" class="jieshao">
        全周期心脏健康检测管理解决方案
      </van-col>
    </van-row>
    <van-row class="zd">
      <van-col
        v-for="(item, index) in fangshi"
        :key="index"
        style="margin-top: 1rem"
      >
        <div class="fs">
          <div
            class="fs_biaoti"
            :style="{ width: index === 3 ? 4.25 + 'rem' : 2.5 + 'rem' }"
          >
            {{ item.title }}
          </div>
          <div class="fs_neirong">{{ item.neirong }}</div>
        </div>
      </van-col>
    </van-row>
    <van-row type="flex" justify="center">
      <van-col class="imgsize">
        <img src="~assets/mobile/service.png" alt="" />
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "PersonService",
  data() {
    return {
      fangshi: [
        {
          title: "诊断：",
          neirong: "对潜在患者进行7*24小时心电监测，获取高质量的诊断参考。",
        },
        {
          title: "治疗：",
          neirong:
            "在采用多种治疗手段积极治疗的同时，对患者心电状况和服药情况进行监测与管理，即时评估治疗效果与患者依从度，随时调整治疗策略，提高治疗收益。",
        },
        {
          title: "康复：",
          neirong:
            "定期监测康复期患者心电健康状况，长期干预生活习惯，预防复发。",
        },
        {
          title: "风险评估：",
          neirong: "对高发人群进行风险评估与早期筛查，降低整体医疗成本。",
        },
      ],
    };
  },
};
</script>

<style scoped>
.title .tt {
  position: relative;
  color: #252222;
  font-size: 1.25rem;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 5rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  margin-top: 1.25rem;
  font-size: 0.75rem;
  color: #7e7e7e;
  font-family: "OPPOSansR";
}
.service .biaoti {
  color: #252222;
  font-size: 0.75rem;
}
.service .js {
  margin-top: 1.875rem;
  font-size: 0.75rem;
  color: #7e7e7e;
}
.service {
  margin-bottom: 1.25rem;
}
.fs {
  display: flex;
}
.fs .fs_biaoti {
  color: #383ebd;
  font-size: 0.75rem;
}
.fs .fs_neirong {
  color: #524f4f;
  font-size: 0.75rem;
  line-height: 1rem;
  flex: 1;
}
.imgsize {
  margin-top: 2.5rem;
  width: 17.0625rem;
  height: 11.625rem;
}
.imgsize img {
  width: 100%;
  height: 100%;
}
.zd {
  margin-top: 2.5rem;
}
</style>
