<template>
  <div class="box">
    <div class="asd">
      <span class="title">在手机上管理你的健康</span>
    </div>
    <br />
    <div class="js">
      <span>
        下载瑞尔安心APP，购买、使用Anxin动态心电记录仪
        随时监测身体状态，精准干预健康问题
      </span>
    </div>
    <div class="gongsi">
      <img class="img_border" src="~assets/gongsi.png" alt="" />
    </div>
    <div class="iphone">
      <img src="~assets/mobile/iPhone.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.box {
  position: relative;
  width: 100%;
  height: 42.75rem;
  background: url("~assets/pbg.png") no-repeat;
  /* background-size: contain; */
  text-align: center;
}
.asd {
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #ffffff;
  display: inline-block;
  margin-top: 3.75rem;
}
.js {
  display: inline-block;
  margin-top: 0.75rem;
  width: 18.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #e4e4e4;
}
.gongsi {
  margin: 0 auto;
  /* width: 5.625rem; */
  height: 5.625rem;
  margin-top: 1.875rem;
}
img {
  /* width: 100%; */
  height: 100%;
}
.iphone {
  position: absolute;
  bottom: 0;
  /* width: 11.25rem; */
  height: 25rem;
  left: 50%;
  transform: translate(-50%);
}
.img_border {
  border: 0.125rem solid #e4e4e4;
  padding: 0.3125rem;
  background: #fff;
  border-radius: 0.25rem;
}
</style>
