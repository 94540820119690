<template>
  <div class="box">
    <how-to :msg="msg"></how-to>
  </div>
</template>

<script>
import HowTo from "components/content/howto/mobile/HowTo";
export default {
  name: "HomeSay",
  components: {
    HowTo,
  },
  data() {
    return {
      msg: {
        title: "他们怎么说",
        todo: "一些使用者的评价",
        msgdata: [
          {
            msg: "长期监测、远程诊断、生活干预，这种模式是对慢病管理模式的革命性改变，我很期待瑞尔安心的这种变革对未来医疗行业的影响。",
            local: "悉尼大学医药健康学院 ",
            name: "Clara Chow教授",
          },
          {
            msg: "这是我见过的最小的医疗级动态心电监测设备。借助Anxin动态心电记录仪和安心服务，我不仅能够随时监测病人的心电情况，还能够使用瑞尔安心健康管理中心对心电数据进行远程诊断。",
            local: "北京市海淀区某三甲医院",
            name: "单医生",
          },
          {
            msg: "Anxin动态心电记录仪完全改变了我们对传统Holter佩戴麻烦、容易脱落的印象。与此同时，和传统Holter相比，Anxin动态心电记录仪对房颤诊断的准确度不仅没有降低，反而大大提高了，这真的很出乎我的意料。",
            local: "知名医生集团创始人",
            name: "张先生",
          },
          {
            msg: "Anxin动态心电记录仪对我的帮助很大，作为程序员，我需要经常熬夜加班，每月定期佩戴Anxin让我能够及时了解自己的身体状况，远离“猝死”风险。",
            local: "安徽省的刘先生",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  color: #252222;
  font-size: 2.5vw;
}
.title .touse {
  color: #524f4f;
  font-size: 0.833vw;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
}
</style>
