<template>
  <div class="box">
    <van-row class="cont">
      <van-col span="24">
        <span>{{ msg.title }}</span>
      </van-col>
    </van-row>
    <van-row class="touse">
      <van-col span="24">
        <span>{{ msg.todo }}</span>
      </van-col>
    </van-row>
    <van-row class="cardchoose">
      <van-col
        span="12"
        v-for="(item, index) in msg.msgdata"
        :key="index"
        :style="{
          'margin-top': index === 2 ? 1 + 'rem' : '',
          'margin-right': (index + 1) % 2 != 0 ? 0.5 + 'rem' : '',
        }"
      >
        <!-- <div class="iig" @mouseenter="enter(index)" @mouseleave="leave(index)"> -->
        <div class="iig">
          <!-- <img src="~assets/dian2.svg" alt="" v-if="activited === index" />
          <img src="~assets/dian1.svg" alt="" v-else /><br /> -->
          <img src="~assets/dian1.svg" alt="" />
          <div class="tt">
            <div class="ttitle">{{ item.msg }}</div>
          </div>
          <div class="local" style="margin-top: 1rem">
            {{ item.local }}
          </div>
          <div class="local" style="margin-bottom: 1.5rem">
            {{ item.name }}
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "HomeSay",
  data() {
    return {
      activited: 0,
    };
  },
  props: {
    msg: {
      typeof: Array,
    },
  },
  methods: {
    // enter(a) {
    //   console.log(a);
    //   this.activited = a;
    // },
    // leave() {
    //   this.activited = "";
    // },
  },
  mounted() {
    console.log(this.msg);
  },
};
</script>

<style scoped>
.box {
  margin-bottom: 5rem;
}
.cont {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #252222;
}
.touse {
  color: #524f4f;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
}
.cardchoose {
  margin: 0rem 0.5rem;
}
/* .ttitle{
  margin: 0 0.5rem;
} */
.van-col--12 {
  width: 48%;
  text-align: center;
  border: 0.052vw solid #ffffff;
  border-radius: 0.9375rem;
  position: relative;
  box-shadow: 0 0.125rem 1rem 0 rgb(0 0 0 / 7%);
}
/* .van-col--12:hover {
  box-shadow: 0 0.125rem 1rem 0 rgb(0 0 0 / 7%);
} */
/* .van-col--offset-1 {
  margin-left: 0.5%;
} */
.cardchoose img {
  width: 1.75rem;
  height: 1.75rem;
  margin-top: -4.083vw;
}
.cardchoose .tt {
  font-size: 0.75rem;
  line-height: 1.1rem;
  color: #7e7e7e;
  display: flex;
  width: 90%;
  margin: 0 auto;
  font-family: "OPPOSansR";
  justify-content: center;
  text-align: center;
  align-items: center;
}
.cardchoose .local {
  color: #252222;
  font-size: 0.625rem;
  line-height: 1rem;
  width: 8rem;
  margin: 0 auto;
  /* margin-top: 1em; */
}
.van-col--12 {
  margin-top: 2.5rem;
}
.step {
  /* margin-left: 6.813vw;
  margin-right: 6.813vw; */
  margin-top: 0.417vw;
  color: #383ebd;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
</style>
