<template>
  <div class="tbox">
    <van-row>
      <van-col :span="24" class="title">
        <div class="tt">
          安心系统
          <div class="bg"></div>
        </div>
      </van-col>
    </van-row>
    <van-row>
      <van-col :span="24" class="jieshao">
        是一套完善的心脏健康检测管理解决方案，让医生可以专注于解决患者的健康问题。
      </van-col>
    </van-row>
    <van-row>
      <van-col :span="24" class="sbname"> Anxin动态心电记录仪 </van-col>
    </van-row>
    <van-row>
      <van-col :span="24" class="jieshao">
        经过NMPA认证的专业医疗器械小巧、舒适、准确、7*24小时不间断监测
      </van-col>
    </van-row>
    <van-row type="flex" justify="space-around" class="top_tp">
      <van-col :span="12" class="img">
        <img src="~assets/person1.png" alt="" />
      </van-col>
      <van-col :span="12">
        <van-row>
          <van-col :span="24" class="sbimg">
            <img src="~assets/toperson/shebei.png" alt="" />
          </van-col>
          <van-col :span="24" class="col_sbname">
            <p>（第二代Anxin动态心电记录仪）</p>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title .tt {
  position: relative;
  color: #252222;
  font-size: 1.25rem;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 5rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.jieshao {
  margin-top: 1.25rem;
  font-size: 0.75rem;
  color: #7e7e7e;
  font-family: "OPPOSansR";
}
.sbname {
  margin-top: 2.5rem;
  font-size: 0.75rem;
}
.img {
  height: 10.375rem;
  width: 8.3125rem;
}
img {
  width: 100%;
  height: 100%;
}
.sbimg img {
  width: 8.8125rem;
  height: 5.6875rem;
}
.sbimg {
  width: 100%;
  margin-top: 1.875rem;
}
.col_sbname p {
  width: 12.4375rem;
  color: #a4a0a0;
  font-size: 0.625rem;
  line-height: 0.75rem;
  transform: scale(0.8);
  margin-left: -1rem;
}
.top_tp {
  margin-top: 1.5rem;
}
</style>
