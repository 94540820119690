<template>
  <div>
    <van-row class="wetodo">
      <van-col span="24">
        <span>我们做了什么？</span>
      </van-col>
    </van-row>
    <van-row class="si">
      <van-col span="24">
        <span class="model">为了革新心血管疾病管理模式</span>
      </van-col>
    </van-row>
    <div class="setp">
      <van-row class="stepicon">
        <van-col span="4" v-for="(item, index) in msgdata" :key="index">
          <img
            :src="item.img1"
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            alt=""
            v-if="activited === index"
          />
          <img
            :src="item.img2"
            alt=""
            @mouseenter="enter(index)"
            @mouseleave="leave(index)"
            v-else
          /><br />
          <div
            class="tt"
            :style="{ color: activited === index ? '#383EBD' : '#524F4F' }"
          >
            {{ item.title }}
          </div>
        </van-col>
      </van-row>
      <div class="stepcontent">
        <section v-for="(item, index) in distype" :key="index">
          <div v-if="activited === index">
            <van-card class="s-card">
              <template #tags>
                <van-image class="asd" :src="item.img1" />
                <div>
                  <dl class="textList">
                    <dt class="title">
                      {{ item.title1 }}
                    </dt>
                    <div class="tcont">
                      {{ item.title2 }}
                    </div>
                    <div class="tcont">
                      {{ item.title3 }}
                    </div>
                    <div class="tcont">
                      {{ item.title4 }}
                    </div>
                    <div class="zcheck">
                      <div
                        class="check"
                        v-for="(item, index) in item.list"
                        :key="index"
                      >
                        <a>
                          <img src="~assets/Check.png" alt="" />
                          {{ item }}
                        </a>
                      </div>
                    </div>
                  </dl>
                </div>
              </template>
            </van-card>
          </div>
        </section>
      </div>
      <div class="bottom"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeContent",
  data() {
    return {
      activited: 1,
      time: true,
      distype: [
        {
          img1: require("../../../../assets/Mask.png"),
          title1: "96.6%＞42%，7天持续心电监测对心率失常发现率的惊人提升",
          title2:
            "传统的多导动态心电记录仪单次佩戴时间为24~48小时，房颤发现率仅为42%",
          title3:
            "irealcare动态心电记录仪采用多种技术创新，使患者单次佩戴时间延长到了7天，使偶发性房颤的发现率提高到了96.6%",
          list: ["高达96.6%的房颤发现率", "长达7天的连续心电监测"],
        },
        {
          img1: require("../../../../assets/mobile/Mask2.png"),
          title1: "无感佩戴，正常生活",
          title2: "IPx3级防水透气、",
          title3: "低致敏性、",
          title4: "无束缚，依从度高达97.5%",
          list: ["IPx3级防水", "透气、低致敏性、无束缚", "依从度高达97.5%"],
        },
        {
          img1: require("../../../../assets/mobile/Mask3.png"),
          title1: "可信的动态心电诊断报告",
          title2:
            "每一份心电诊断报告都由资深心电诊断医师在基于深度学习的人工智能辅助下完成，并经过瑞尔安心健康管理中心的专家组审核，拥有极高的可信度。",
          title3: "",
          list: [
            "资深心电诊断医师",
            "人工智能辅助",
            "专家组审核",
            "极高的可信度",
          ],
        },
        {
          img1: require("../../../../assets/mobile/Mask4.png"),
          title1: "全方位的生活干预",
          title2:
            "饮食、运动、作息....依据权威资料，为患者提供全方位的生活干预方案，帮助患者养成有利于康复的健康生活习惯。",
          title3: "",
          list: [
            "权威健康干预方案",
            "个性化生活干预策略",
            "全方位健康习惯养成",
          ],
        },
      ],
      msgdata: [
        {
          img1: require("../../../../assets/step/ta1.svg"),
          img2: require("../../../../assets/step/t1.svg"),
          title: "将房颤发现率由42%提升到96%",
        },
        {
          img1: require("../../../../assets/step/ta2.svg"),
          img2: require("../../../../assets/step/t2.svg"),
          title: "让佩戴者正常生活",
        },
        {
          img1: require("../../../../assets/step/ta3.svg"),
          img2: require("../../../../assets/step/t3.svg"),
          title: "提供一份可信的高价值诊断报告",
        },
        {
          img1: require("../../../../assets/step/ta4.svg"),
          img2: require("../../../../assets/step/t4.svg"),
          title: "根据诊断，为患者提供多方面的生活干预",
        },
      ],
    };
  },
  methods: {
    stepclick(e) {
      this.activited = e;
    },
    enter(a) {
      // console.log(a);
      this.activited = a;
      this.clearTime();
    },
    leave() {
      this.timer();
    },
    timer() {
      var i = 0;
      this.clearTimeSet = setInterval(() => {
        if (i === 3) {
          i = -1;
        }
        i++;
        this.activited = i;
        // console.log(this.activited);
      }, 1000);
    },
    clearTime() {
      clearInterval(this.clearTimeSet);
    },
  },
  mounted() {
    this.timer();
  },
};
</script>

<style scoped>
.wetodo {
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
}
.si {
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  margin-top: 0.75rem;
}
.setp {
  margin-top: 1.25rem;
}
.stepicon .van-col--4 img {
  width: 3.125rem;
  height: 3.125rem;
}
.stepicon .van-col--4 {
  width: 25%;
  text-align: center;
}
.stepicon .van-col--4 .tt {
  /* margin-top: 1rem; */
  font-size: 0.75rem;
  transform: scale(0.75);
}
.asd {
  width: 20.4375rem;
  /* height: 11.375rem; */
  margin-top: 0.75rem;
  margin-bottom: 1rem;
}
.stepcontent {
  height: 30.125rem;
}
.s-card {
  /* display: table-cell; */
  text-align: center;
  /* height: 29.125rem; */
  margin: 0 0.5rem;
  border-radius: 0.625rem;
}
dl dt {
  text-align: left;
  color: #252222;
  font-size: 0.875rem;
  margin-bottom: 1rem;
}
dl .tcont {
  text-align: left;
  color: #7e7e7e;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}
.zcheck {
  margin: 1.25em 0em;
}
.check {
  float: left;
  text-align: left;
  width: 49%;
  font-size: 0.75rem;
  transform: scale(0.83);
  line-height: 1rem;
  color: #524f4f;
  margin-bottom: 0.75rem;
}
/* .check{
  display: flex;
} */
.textList,
.model {
  font-family: "OPPOSansR";
}
</style>
