<template>
  <div class="box">
    <!-- <div class="zhegai" ref="elements" v-show="moveto"></div> -->
    <patient-top class="top"></patient-top>
    <patient-mid class="mid"></patient-mid>
    <patient-bottom class="bottom"></patient-bottom>
    <patient-yao class="yao"></patient-yao>
    <patient-insurance class="insurance"></patient-insurance>
    <patient-phone class="phone"></patient-phone>
  </div>
</template>

<script>
import PatientTop from "./homeChild/PatientTop";
import PatientMid from "./homeChild/PatientMid";
import PatientBottom from "./homeChild/PatientBottom";
import PatientYao from "./homeChild/PatientYao";
import PatientInsurance from "./homeChild/PatientInsurance";
import PatientPhone from "./homeChild/PatientPhone";
export default {
  name: "ToPatient",
  data() {
    return {
      tishi: true,
      moveto: false,
      height: 0,
      i: 0,
    };
  },
  components: {
    PatientTop,
    PatientMid,
    PatientBottom,
    PatientYao,
    PatientInsurance,
    PatientPhone,
  },
  methods: {
    handleScroll() {
      // 页面滑动的距离
      // let scrollTop = document.documentElement.scrollTop;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      // console.log("scroll" + scroll);
      if (scroll > 0) {
        // console.log("up");
        this.moveto = true;
      } else {
        // console.log("down");
        if (scrollTop >= this.height) {
          this.moveto = true;
        } else {
          this.moveto = false;
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.$nextTick(function () {
    //   // console.log(this.$refs.elements);
    //   // this.height = this.$refs.elements.clientHeight;
    // });
  },
};
</script>

<style scoped>
.mid {
  margin-top: 7.25rem;
}
.zhegai {
  height: 5.52rem;
}
.top {
  margin-top: 6.25vw;
}
.bottom {
  margin-top: 8.167vw;
}
.yao {
  margin-top: 6.25rem;
}
.insurance {
  margin-top: 6.25rem;
}
.phone {
  margin-top: 9.375vw;
  margin-bottom: 6.25rem;
}
</style>
