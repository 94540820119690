<template>
  <div @click.stop>
    <div class="bannerbox" ref="element" :style="{ background: activecolor }">
      <!--  -->
      <div class="logo" @click="tohome">
        <img
          :src="
            logoshow
              ? require('../../../../assets/logo1.svg')
              : require('../../../../assets/logo2.svg')
          "
        />
        <!-- <img src="~assets/logo2.svg" alt="" v-else /> -->
      </div>
      <div class="mid"></div>
      <div class="right">
        <div class="img" @click="toshow">
          <img
            :src="
              logoshow
                ? require('../../../../assets/menu-on.svg')
                : require('../../../../assets/mobile/menu-on.svg')
            "
            alt=""
            v-if="show"
          />
          <img src="~assets/menu-off.svg" alt="" v-else />
        </div>
      </div>
    </div>
    <collapse-transition>
      <div class="isshow" v-if="!show">
        <ul class="isshowmid">
          <li v-for="(item, index) in bar" :key="index" @click="topage(index)">
            <a>{{ item }}</a>
          </li>
        </ul>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import collapseTransition from "assets/js/collapse";
export default {
  name: "HomeNavigation",
  components: {
    collapseTransition,
  },
  data() {
    return {
      isheight: 0,
      //height: document.documentElement.clientHeight, //bannner若要占住一整个屏幕
      height: 509,
      isShow: true,
      show: true,
      activecolor: "",
      bar: ["首页", "面向患者", "面向专业人员", "关于我们"],
      img: require("../../../../assets/menu-on.svg"),
      logoshow: true,
    };
  },
  watch: {
    show: function (newVal, oldVal) {
      if (!newVal) {
        console.log(oldVal);
        this.$emit("showzhegai", "showzhegai");
      } else {
        this.$emit("showzhegai", "wushowzhegai");
      }
    },
  },
  methods: {
    toshow() {
      this.show = !this.show;
      if (!this.show) {
        this.logoshow = false;
        this.activecolor = "#FFFFFF";
      } else {
        this.logoshow = true;
        this.activecolor = "";
      }
    },
    handleScroll() {
      //监听到滚动，是下拉菜单关闭
      this.show = true;
      //发送给home关闭遮盖
      this.$emit("showzhegai", "wushowzhegai");
      // 页面滑动的距离
      let scrollTop = document.documentElement.scrollTop;
      // 当页面滑动的距离大于300px时元素显示，否则不显示
      if (scrollTop >= this.height) {
        this.isShow = false;
        this.logoshow = false;
        this.activecolor = "#FFFFFF";
      } else {
        this.isShow = true;
        this.logoshow = true;
        this.activecolor = "";
      }
    },
    topage(index) {
      if (index === 3) {
        this.$router.push({ path: "/aboutus" });
      }
      if (index === 2) {
        this.$router.push({ path: "/toperson" });
      }
      if (index === 1) {
        this.$router.push({ path: "/topatient" });
      }
      if (index === 0) {
        this.$router.push({ path: "/" });
      }
    },
    tohome() {
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isheight = this.$refs.element.offsetHeight;
    //若不是整个屏幕则发送
    // this.$bus.on("bannerheight", (value) => {
    //   this.height = value;
    //   // console.log(value)
    // });
  },
  created() {
    document.addEventListener("click", () => {
      if (!this.show) {
        this.show = true;
        this.activecolor = "";
        this.logoshow = true;
      }
    });
  },
};
</script>

<style scoped>
.bannerbox {
  position: absolute;
  /* height: 3.25rem; */
  display: flex;
  align-items: center;
  z-index: 11;
  width: 100%;
  /* background-color: black; */
  /* transition: all 0.6s; */
  position: fixed;
  /* background-image: url("~assets/zhegai.svg"); */
}
.bannerbox .logo img {
  margin-left: -2.6rem;
  margin-top: 0.75rem;
  /* width: 7.0625rem; */
  height: 1.4375rem;
}
.mid {
  flex: 1;
}
.mid {
  width: 1.875rem;
}
.right .img {
  float: right;
  margin-right: 0.875rem;
  margin-top: 0.7rem;
}
.isshow {
  position: absolute;
  z-index: 11;
  position: fixed;
  left: 50%;
  width: 101%;
  top: 52px;
  transform: translate(-50%);
}
.isshowmid {
  /* position: relative; */
  text-align: center;
  margin: 0 auto;
  color: #524f4f;
  width: 101%;
  font-size: 0;
  background-color: #ffffff;
}
.isshowmid li {
  /* position: absolute; */
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  /* bottom: 0px */
  border-top: 1px solid #e4e4e4;
}
.logo {
  height: 51.994px;
}
img {
  width: 100%;
  height: 100%;
}

/* transitions */
.collapse-wrap {
  width: 200px;
  background: pink;
  color: #fff;
  margin-left: 100px;
}
.collapse_btn {
  width: 100px;
  height: 50px;
  border: 1px solid;
  border-radius: 25px;
  margin-left: 150px;
  text-align: center;
  line-height: 50px;
  font-size: 22px;
}
.content1 {
  height: 120px;
  font-size: 20px;
}
</style>
