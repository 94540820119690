var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tbox"},[_c('van-row',[_c('van-col',{staticClass:"title",attrs:{"span":24}},[_c('div',{staticClass:"tt"},[_vm._v(" 临床研究 "),_c('div',{staticClass:"bg"})])])],1),_c('van-row',[_c('van-col',{staticClass:"jieshao",attrs:{"span":24}},[_vm._v(" 瑞尔安心作为领先的数字医疗设备提供商，一直致力于帮助企业及研究者获取更准确、可信的第一手临床实验数据，助力全球心血管系统新药物与新疗法的诞生 ")])],1),_c('van-row',{attrs:{"type":"flex","justify":"center space-around"}},_vm._l((_vm.research),function(item,index){return _c('van-col',{key:index,staticClass:"asd",attrs:{"span":12}},[_c('div',{staticClass:"imgstyle",style:({
          width: `${item.imgwidth}rem`,
          height: `${item.imgheight}rem`,
          top: `${item.top}%`,
        })},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('div',{staticClass:"fontstyle",style:({
          top: `${item.top}%`,
        })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }