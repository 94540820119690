<template>
  <div class="box" ref="activeimg">
    <van-row class="cont">
      <van-col span="24">
        <span>{{ msg.title }}</span>
      </van-col>
    </van-row>
    <van-row class="touse">
      <van-col span="24">
        <span>{{ msg.todo }}</span>
      </van-col>
    </van-row>
    <van-row class="cardchoose">
      <van-col
        span="24"
        v-for="(item, index) in msg.msgdata"
        :key="index"
        style="margin-top: 4rem"
        @click="click(index)"
      >
        <!-- <div class="iig" @mouseenter="enter(index)" @mouseleave="leave(index)"> -->
        <van-row type="flex" justify="center">
          <van-col class="step">{{ item.step }}:</van-col>
          <van-col class="local">{{ item.local }}</van-col>
        </van-row>
        <van-row type="flex" justify="center" class="tt">
          <van-col class="ttitle">{{ item.msg }}</van-col>
        </van-row>
        <div class="imgsize">
          <img :src="item.activeimg" alt="" v-if="activited === index" />
          <img :src="item.imgbg" alt="" v-else /><br />
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "HomeSay",
  data() {
    return {
      activited: "",
      flag: 0,
    };
  },
  props: {
    msg: {
      typeof: Array,
    },
    touse: {
      typeof: String,
    },
  },
  methods: {
    click(e) {
      this.activited = e;
      this.clearTime();
    },
    timer() {
      this.TimeSet = setTimeout(() => {
        this.activited = 0;
        setTimeout(() => {
          console.log("222222222222");
          this.activited = 1;
          setTimeout(() => {
            this.activited = 2;
            setTimeout(() => {
              this.activited = 3;
              setTimeout(() => {
                this.activited = "";
              }, 6220);
            }, 9240);
          }, 6470);
        }, 12310);
      }, 0);
    },
    clearTime() {
      clearTimeout(this.TimeSet);
      this.TimeSet = null;
    },
    handleScroll() {
      // let that = this;
      //垂直滚动的值兼容问题
      let scrollTopE =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.$refs.activeimg) {
        //offsetTop 属性指定的父坐标的计算顶端位置 offsetHeight获取对象的高度
        let bannerTop = this.$refs.activeimg.offsetTop;
        let bannerHeight = this.$refs.activeimg.offsetHeight;
        //屏幕的高度
        let screenHeight = window.screen.availHeight;
        // console.log((bannerTop-screenHeight)<scrollTopE && scrollTopE<(bannerTop+bannerHeight))
        // console.log((bannerTop-screenHeight)<scrollTopE)
        // console.log(scrollTopE<(bannerTop+bannerHeight))
        // console.log(bannerTop+bannerHeight)
        // console.log("scrollTopE:"+scrollTopE+";bannerTop"+bannerTop+";bannerHeight"+bannerHeight+";screenHeight"+screenHe
        //判断是否滚动到操作简便的
        // console.log(bannerTop, "bannerTop");
        // console.log(screenHeight, "screenHeight");
        // console.log(scrollTopE, "scrollTopE");
        // console.log(bannerTop - screenHeight, "bannerTop - screenHeight");
        // console.log(bannerTop + bannerHeight, "bannerTop + bannerHeight");
        if (
          bannerTop - screenHeight < scrollTopE &&
          scrollTopE < bannerTop + bannerHeight
        ) {
          //到该位置，开始播放视频
          console.log("11111111111111111111111");
          if (this.flag === 0) {
            this.flag = 1;
            this.timer();
          }
        } else {
          console.log("------------------------------");
          this.clearTime();
        }
      }
    },
  },
  mounted() {
    let that = this;
    that.$nextTick(function () {
      window.addEventListener("scroll", this.handleScroll);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.box {
  margin-bottom: 5rem;
}
.cont {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: #252222;
}
.local {
  color: #252222;
  font-size: 00.875rem;
  line-height: 1.2rem;
}
.touse {
  color: #524f4f;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.625vw;
  font-family: "OPPOSansR";
}
.cardchoose .tt {
  font-size: 0.75rem;
  line-height: 1.1rem;
  color: #7e7e7e;
  display: flex;
  width: 60%;
  font-family: "OPPOSansR";
  margin: 0.75rem auto;
}
.step {
  /* margin-left: 6.813vw;
  margin-right: 6.813vw; */
  margin-top: 0.417vw;
  color: #383ebd;
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.imgsize {
  width: 17.5rem;
  height: 11.2rem;
  margin: 0 auto;
}
.imgsize img {
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
}
</style>
