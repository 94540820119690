<template>
  <div class="tbox">
    <van-row style="margin-bottom: 2.5rem">
      <van-col :span="24" class="title">
        <div class="tt">
          联系瑞尔安心
          <div class="bg"></div>
        </div>
      </van-col>
    </van-row>
    <el-form ref="form" :model="form" class="mobileform" :rules="rule">
      <el-form-item label="您的姓名" prop="userName"
        ><el-input
          v-model="form.userName"
          class="userName"
          placeholder="请输入姓名"
        ></el-input
      ></el-form-item>
      <el-form-item label="联系方式" prop="phoneOrEmail"
        ><el-input
          v-model="form.phoneOrEmail"
          class="phoneOrEmail"
          placeholder="请输入联系方式"
        ></el-input
      ></el-form-item>
      <el-form-item label="反馈内容" prop="advance"
        ><el-input
          v-model="form.advance"
          class="pdesc"
          :rows="4"
          type="textarea"
          placeholder="请输入反馈内容"
        ></el-input
      ></el-form-item>
      <el-button
        @click="onSubmit()"
        class="btn"
        :style="{
          'border-color':
            form.advance != '' && form.phoneOrEmail != '' && form.userName != ''
              ? '#c6e2ff'
              : '',
          'background-color':
            form.advance != '' && form.phoneOrEmail != '' && form.userName != ''
              ? '#383EBD'
              : '',
        }"
        >提交</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { ques } from "@/network/ques.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      form: {
        userName: "",
        phoneOrEmail: "",
        advance: "",
      },
      rule: {
        userName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        phoneOrEmail: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
        ],
        advance: [
          { required: true, message: "反馈内容不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          ques(this.form);
          Toast.success("提交成功");
          this.form.userName = "";
          this.form.phoneOrEmail = "";
          this.form.advance = "";
        }
      });
    },
  },
};
</script>

<style scoped>
.title .tt {
  position: relative;
  color: #252222;
  font-size: 1.25rem;
}
.bg {
  position: absolute;
  bottom: 0vw;
  width: 7.5rem;
  height: 0.375rem;
  opacity: 0.4;
  background-color: #0093b3;
  z-index: -10;
}
.mobileform .btn {
  margin-top: 2.5rem;
  width: 10.875rem;
  /* height: 1.9375rem; */
  background-color: #e4e4e4;
  color: #ffffff;
  font-size: 0.875rem;
  border-color: #e4e4e4;
  /* line-height: 1.125rem; */
}
.mobileform .el-button:active {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
.mobileform {
  text-align: center;
}
input[type="text"] {
  -webkit-appearance: none !important;
}
input,
textarea,
::v-deep .el-textarea__inner {
  /* border: none; */
  outline: none;
  -webkit-appearance: none !important;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}
</style>
